(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("stockcars-filter-bundle", [], factory);
	else if(typeof exports === 'object')
		exports["stockcars-filter-bundle"] = factory();
	else
		root["stockcars-filter-bundle"] = factory();
})(window, function() {
return 